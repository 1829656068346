import gsap from 'gsap'
import each from 'lodash/each'

import Component from '../classes/Component'
import { split } from '../utils/text'

export default class Preloader extends Component {
  constructor({ canvas }) {
    super({
      element: '.preloader',
      elements: {
        title: '.preloader__text',
        percent: '.preloader__percent',
        percentText: '.preloader__percent__text',
        images: document.querySelectorAll('.js-preload'),
      },
    })

    this.canvas = canvas

    window.TEXTURES = {}

    split({
      element: this.elements.title,
      expression: '<br>',
    })
    split({
      element: this.elements.title,
      expression: '<br>',
    })

    this.elements.titleSpans = this.elements.title.querySelectorAll('span span')

    this.length = 0

    // console.log(this.element, this.elements)

    // this.show()
    this.createLoader()
  }

  createLoader() {
    // console.log(this.elements.images.length)

    each(this.elements.images, (element) => {
      // const image = new Image()
      element.onload = (_) => {
        element.classList.add('loaded')
        this.onAssetLoaded(element)
      }
      element.src = element.getAttribute('data-src')
    })
  }

  /**
   * Animation
   */
  show() {
    gsap.to(
      [this.elements.title, this.elements.percent, this.elements.percentText],
      {
        duration: 0.6,
        autoAlpha: 1,
      }
    )
  }
  onAssetLoaded(image) {
    this.length += 1

    const percent = this.length / this.elements.images.length

    // console.log(this.length, this.elements.images.length)
    // console.log(Math.round(percent * 100))

    this.elements.percentText.innerHTML = `${Math.round(percent * 100)}%`
    if (percent == 1) {
      this.onLoaded()
    }
  }

  onLoaded() {
    return new Promise((resolve) => {
      this.emit('completed')

      this.animateOut = gsap.timeline({
        delay: 0.4,
        onComplete: () => {
          this.destroy()
        },
      })
      this.animateOut
        .to(this.elements.titleSpans, {
          duration: 0.8,
          ease: 'expo.out',
          autoAlpha: 0,
          // stagger: 0.1,
          // y: '100%',
        })
        .to(
          this.elements.percentText,
          {
            duration: 0.8,
            ease: 'expo.out',
            autoAlpha: 0,
            // stagger: 0.1,
            //   y: '100%',
          },
          0
        )
        .to(
          this.element,
          {
            duration: 0.8,
            ease: 'expo.out',
            autoAlpha: 0,
            // scaleY: 0,
            // transformOrigin: 'top',
          },
          0.2
        )
    })
  }

  destroy() {
    this.element.parentNode.removeChild(this.element)
  }
}
