import gsap from 'gsap'
import { CustomEase } from 'gsap/CustomEase'

gsap.registerPlugin(CustomEase)
// CustomEase.create('fastEase', '0.49, 0.01, 0.19, 0.89')
// CustomEase.create('expoEase', '0,.62,0,.99')
CustomEase.create('easeInOut', '0.075, 0.82, 0.165, 1')
// CustomEase.create('smoothEase', '0.2, 1, 0.24, 1')

// import lerp from '../utils/math'
import Prefix from 'prefix'

export default class GhostCursor {
  constructor(opts) {
    this.cursor = document.querySelector('.cursor')
    this.ghost = document.querySelector('.cursor__ghost')

    this.links = [...document.querySelectorAll('a')]
    this.modalBtn = document.querySelector('.nav__trigger__btn')
    this.links.push(this.modalBtn)

    this.cursorSize = 20
    this.ghostSize = 50
    this.lerp = 0.1
    this.gsapParams = opts.gsapParams

    this.x = {
      start: 0,
      end: 0,
      current: 0,
      target: 0,
    }

    this.y = {
      start: 0,
      end: 0,
      current: 0,
      target: 0,
    }

    this.scale = {
      current: 1,
      target: 1.5,
    }

    this.isClicked = false

    this.transformPrefix = Prefix('transform')

    this.setStyles()
    this.createTween()
  }

  setStyles() {
    if ('ontoutchstart' in window) {
      this.cursor.style.display = 'none'
      this.ghost.style.display = 'none'
    }
  }

  createTween() {
    this.enterTl = gsap.timeline({
      paused: true,
      ease: 'easeInOut',
    })

    this.enterTl = gsap.timeline({
      paused: true,
      ease: 'easeInOut',
    })
  }

  onTouchDown(e) {
    this.isClicked = true

    this.x.start = e.touches ? e.touches[0].clientX : e.clientX
    this.y.start = e.touches ? e.touches[0].clientY : e.clientY
  }

  onTouchMove(e) {
    this.x.end = e.touches ? e.touches[0].clientX : e.clientX
    this.y.end = e.touches ? e.touches[0].clientY : e.clientY

    this.x.target = this.x.end
    this.y.target = this.y.end

    gsap.set(this.cursor, {
      x: this.x.target - this.cursorSize / 2,
      y: this.y.target - this.cursorSize / 2,
    })
  }

  onTouchUp(e) {
    this.x.end = e.touches ? e.changedTouches[0].clientX : e.clientX
    this.y.end = e.touches ? e.changedTouches[0].clientY : e.clientY
  }

  onEnter() {
    this.enterTl = gsap
      .timeline()
      .to(this.cursor, {
        duration: 0.15,
        scale: 5,
      })
      .to(this.ghost, {
        duration: 0.05,
        scale: 0.2,
      })

    this.enterTl.restart()
  }

  onLeave() {
    this.leaveTl = gsap
      .timeline()
      .to(this.cursor, {
        duration: 0.15,
        scale: 1,
      })
      .to(this.ghost, {
        duration: 0.05,
        scale: 1,
      })
    this.leaveTl.restart()
  }

  update() {
    this.x.current = gsap.utils.interpolate(
      this.x.current,
      this.x.target,
      this.lerp
    )

    this.y.current = gsap.utils.interpolate(
      this.y.current,
      this.y.target,
      this.lerp
    )

    gsap.set(this.ghost, {
      x: this.x.current - this.ghostSize / 2,
      y: this.y.current - this.ghostSize / 2,
    })

    // this.ghost.style.transform = `translate(${
    //   this.x.current - this.ghostSize / 2
    // }px, ${this.y.current - this.ghostSize / 2}px)`
  }

  addEventsListeners() {
    for (let i = 0; i < this.links.length; i++) {
      const element = this.links[i]
      element.addEventListener('mouseenter', this.onEnter.bind(this), {
        passive: true,
      })

      element.addEventListener('mouseleave', this.onLeave.bind(this), {
        passive: true,
      })
    }
  }

  removeEventsListeners() {
    for (let i = 0; i < this.links.length; i++) {
      const element = this.links[i]
      element.removeEventListener('mouseenter', this.onEnter.bind(this))
      element.removeEventListener('mouseleave', this.onLeave.bind(this))
    }
  }
}

// const cursor = new GhostCursor(cursorOptions)
