/**
 * リサイズ時はDOM要素再取得
 *  */
import gsap from 'gsap'
// import Prefix from 'prefix'

import Page from '../../classes/Page'

import InertiaScroll from '../../components/InertiaScroll'
import Toggle from '../../components/Toggle'
import DropDown from '../../components/DropDown'

export default class Sub extends Page {
  constructor() {
    super({
      id: 'sub',

      element: '.sub',
      elements: {
        wrapper: '.sub__wrapper',
        navigation: document.querySelector('.navigation'),
        toggles: document.querySelectorAll('.mod-21-title'),
        dropDown: document.querySelectorAll('.dropdown'),
        // title: '.sub__title',
      },
    })

    this.y = {
      start: 0,
      distance: 0,
      end: 0,
      current: 0,
      last: 0,
      target: 0,
    }

    this.speed = 0
    this.playrate = 0

    this.isDown = false

    // this.transformPrefix = Prefix('transform')
  }

  create() {
    super.create()

    this.createInertiaScroll()

    if (this.elements.toggles) {
      this.createToggle()
    }

    if (this.elements.dropDown) {
      this.createDropDown()
    }
  }

  createInertiaScroll() {
    // console.log('created inertia')
    this.inertiaScroll = new InertiaScroll(this.scroll, this.elements.wrapper)
  }

  createToggle() {
    this.toggle = new Toggle(this.elements.toggles)
  }

  createDropDown() {
    this.dropDown = new DropDown()
  }

  show() {
    super.show()

    if (this.inertiaScroll && this.inertiaScroll.show) {
      this.inertiaScroll.show()
    }
  }

  hide() {
    super.hide()

    if (this.inertiaScroll && this.inertiaScroll.hide) {
      this.inertiaScroll.hide()
    }
  }

  onResize() {
    super.onResize()
    // this.scroll.target = 0

    if (this.inertiaScroll) {
      this.inertiaScroll.onResize()
    }

    // console.log(this.scroll.limit, 'this.scroll.limit')
    // 下層ページにトグルがある場合はclampのlimitに加算
    let toggleHeight = 0
    // ページロード・ページ遷移時のみ
    if (this.elements.toggles && !this.isLoaded) {
      this.isLoaded = true

      const toggleContents = [
        ...document.querySelectorAll('.mod-21-block.is-open .mod-21-content p'),
      ]
      toggleContents.forEach((element) => {
        const height = element.clientHeight
        toggleHeight += height
      })
      // console.log(toggleHeight, 'toggleHeight')
    }
    // console.log(wrapperHeight, toggleHeight, winHeight, 'wrapper, toggle, win')
    if (this.elements.wrapper) {
      this.scroll.limit += toggleHeight
      // console.log(this.scroll.limit, 'this.scroll.limit after')
    }

    this.toggleTitles = [...document.querySelectorAll('.mod-21-title')]
    // リサイズ時にトグル高さ調整
    if (this.toggle) {
      this.toggle.onResize(this.toggleTitles)
    }
  }

  onTouchDown(e) {
    super.onTouchDown()
    // SPのみタッチ操作
    if ('ontouchstart' in document) {
      this.isDown = true
      this.y.start = e.touches ? e.touches[0].clientY : e.clientY
    }
    // console.log(this.y.start, 'this.y.start')
  }

  onTouchMove(e) {
    super.onTouchMove()

    if ('ontouchstart' in document) {
      if (!this.isDown) return
      this.y.end = e.touches ? e.touches[0].clientY : e.clientY

      this.scroll.target += (this.y.start - this.y.end) * 2
      this.y.start = this.y.end

      this.scroll.target = gsap.utils.clamp(
        0,
        this.scroll.limit,
        this.scroll.target
      )
    }
    // this.y.end = e.touches ? e.touches[0].clientY : e.clientY
    // const yDistance = this.y.start - this.y.end
    // this.scroll.target = this.scroll.current - yDistance
    // console.log(this.scroll.target, 'target')
  }

  onTouchUp(e) {
    super.onTouchMove()

    if ('ontouchstart' in document) {
      this.isDown = false
    }
    // this.y.end = e.touches ? e.changedTouches[0].clientY : e.clientY
  }

  onWheel(e) {
    if (this.inertiaScroll) {
      this.inertiaScroll.onWheel(e)
    }
  }

  onTrigger(e) {
    if (this.toggle && this.toggle.onTrigger) {
      this.toggle.onTrigger(e)
    }

    // console.log(this.scroll.limit, 'this.scroll.limit')

    if (this.toggle && this.toggle.height) {
      if (this.toggle.isOpen) {
        // console.log(this.toggle.isOpen, this.toggle.height, 'on Sub')

        this.scroll.limit += this.toggle.height

        // console.log(this.scroll.limit, 'this.scroll.limit after')
      } else if (!this.toggle.isOpen) {
        // console.log(this.toggle.isOpen, this.toggle.height, 'on Sub')
        this.scroll.limit -= this.toggle.height
        // console.log(this.scroll.limit, 'this.scroll.limit after')
      }
    }
  }

  update() {
    // タッチ操作用
    // this.scroll.current = gsap.utils.interpolate(
    //   this.scroll.current,
    //   this.scroll.target,
    //   0.1
    // )

    // if (this.scroll.current < 0.01) {
    //   this.scroll.current = 0
    // }

    // if (this.elements.wrapper) {
    //   this.elements.wrapper.style[this.transformPrefix] = `translateY(${-this.y
    //     .current}px)`
    // }

    // this.speed = Math.min(100, this.y.last - this.scroll.current)
    // this.y.last = this.scroll.current

    //
    if (this.inertiaScroll) {
      this.inertiaScroll.update(this.scroll.current)
    }
  }

  addEventsListeners() {
    super.addEventsListeners()

    this.toggleTitles.forEach((toggle) => {
      toggle.addEventListener('mousedown', this.onTrigger.bind(this), {
        passive: true,
      })
    })

    if (this.dropDown) {
      this.dropDown.addEventsListeners()
    }
    // each(this.elements.toggles, (toggle) => {
    //   toggle.addEventListener('mousedown', this.onTrigger.bind(this), {
    //     passive: true,
    //   })
    // })
  }

  removeEventsListeners() {
    if (this.toggleTitles) {
      this.toggleTitles.forEach((toggle) => {
        toggle.removeEventListener('click', this.onTrigger.bind(this))
      })
    }

    if (this.toggle) {
      this.toggle.removeEventsListeners()
      this.toggle = null
    }

    if (this.dropDown) {
      this.dropDown.removeEventsListeners()
      this.dropDown = null
    }
  }

  destroy() {
    super.destroy()

    if (this.inertiaScroll) {
      this.inertiaScroll.destroy()
      this.inertiaScroll = null
    }
  }
}
