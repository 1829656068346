import Component from '../classes/Component'
// import { BREAKPOINT_PHONE, checkOrientation } from '../utils/breakpoints'

/*--------------------
Footer Class
--------------------*/
export default class Footer {
  constructor() {
    if (document.querySelector('.footer__pagetop__btn')) {
      this.footerBtn = new FooterBtn()
    }
  }

  /**
   * Event
   */
  onWheel(e) {
    if (this.footerBtn) {
      this.footerBtn.onWheel(e)
    }
  }

  onResize() {
    if (this.footerBtn) {
      this.footerBtn.onResize()
    }
  }

  onChangeStart() {
    if (this.footerBtn) {
      this.footerBtn.onChangeStart()
    }
  }

  /**
   * Update
   */
  update() {
    if (this.footerBtn) {
      this.footerBtn.update()
    }
  }
}

/*--------------------
Footer Button Class
--------------------*/
class FooterBtn extends Component {
  constructor() {
    super({
      elements: {
        pageTopBtn: '.footer__pagetop__btn',
        PrevPageBtn: '.footer__prev__btn',
      },
    })

    this.windowH = window.innerHeight
    this.footer = document.querySelector('.footer')
    this.offset = 200
    this.scrollY = 0
  }

  /**
   * Events
   */
  onWheel({ pixelY }) {
    this.scrollY += pixelY

    if (this.scrollY <= 0) {
      this.scrollY = 0
    }
  }

  onResize() {}

  onChangeStart() {
    this.scrollY = 0
  }

  /**
   * Update
   */
  update() {
    if (this.scrollY > this.windowH - this.offset) {
      this.elements.pageTopBtn.classList.add('is-show')
      this.elements.PrevPageBtn.classList.add('is-show')
    } else {
      this.elements.pageTopBtn.classList.remove('is-show')
      this.elements.PrevPageBtn.classList.remove('is-show')
    }
  }
}
