const UAParser = require('ua-parser-js')

const userAgent = (() => {
  class UA {
    constructor() {
      this.uaParser = UAParser()
      // this.body = document.querySelector('body')
      this.body = document.documentElement
    }

    check() {
      this.setUA()
    }

    // getBrowser() {
    //   return this.uaParser.browser.name.replace(/\s+/g, '').toLowerCase()
    // }

    getDevice() {
      const type = this.uaParser.device.type
      // console.log(type, 'type')
      let result = ''

      if (type == 'mobile') {
        result = 'sp'
      } else if (type == 'tb') {
        result = 'tablet'
      } else if (type == undefined) {
        result = 'pc'
      }

      return result
    }

    // getModel() {
    //   return this.uaParser.device.model
    //     ? this.uaParser.device.model.replace(/\s+/g, '').toLowerCase()
    //     : ''
    // }

    // getOS() {
    //   return this.uaParser.os.name.replace(/\s+/g, '').toLowerCase()
    // }

    setUA() {
      // this.body.classList.add(`ua-${this.getBrowser()}`)
      this.body.className = `ua-${this.getDevice()}`
      // this.body.classList.add(`ua-${this.getDevice()}`)
      // if (this.getModel()) {
      //   this.body.classList.add(`ua-${this.getModel()}`)
      // }
      // this.body.classList.add(`ua-${this.getOS()}`)
    }
  }
  return new UA()
})()

export default userAgent
