export default class ModalNav {
  constructor() {
    this.children = [...document.querySelectorAll('.nav__modal__child')]
    this.toggleBtn = [...document.querySelectorAll('.nav__modal__toggle')]

    // toggleボタンクリック時にアニメーション
    for (let i = 0; i < this.toggleBtn.length; i++) {
      let btn = this.toggleBtn[i]
      let child = this.children[i]
      btn.addEventListener('click', () => {
        btn.classList.toggle('is-active')
        child.classList.toggle('is-show')
        if (child.style.maxHeight) {
          child.style.maxHeight = null
        } else {
          child.style.maxHeight = child.scrollHeight + 'px'
        }
      })
    }
  }
}
