// import GSAP from 'gsap'

// export function lerp(a, b, n) {
//   return (1 - n) * a + n * b
// }

export const lerp = (f0, f1, t) => (1 - t) * f0 + t * f1
export const clamp = (val, min, max) => Math.max(min, Math.min(val, max))
// export function lerp (p1, p2, t) {
//   return GSAP.utils.interpolate(p1, p2, t)
// }

// export function clamp (min, max, number) {
//   return GSAP.utils.clamp(min, max, number)
// }

// export function random (min, max) {
//   return GSAP.utils.random(min, max)
// }

// export function map (valueToMap, inMin, inMax, outMin, outMax) {
//   return GSAP.utils.mapRange(inMin, inMax, outMin, outMax, valueToMap)
// }
