export default class VideoPlayer {
  constructor() {
    this.videoPlayer = document.querySelector('.video__player')
    this.video = this.videoPlayer.querySelector('video')
    this.videoPlaylist = this.videoPlayer.querySelector('figcaption')
    this.source = this.video.querySelectorAll('source')
    this.allLnks = this.videoPlaylist.children
    this.linkNum = this.allLnks.length

    this.playlistLinks = []
    this.path =
      this.allLnks[0].href.split('/').reverse().slice(1).reverse().join('/') +
      '/'
    // console.log(this.path, 'this.path')
    this.index = 0
    this.current = 0

    // this.video.removeAttribute('controls')
    // this.video.removeAttribute('poster')

    this.init()
    this.addEventListeners()

    // this.video.play()
  }

  init() {
    for (let i = 0; i < this.linkNum; i++) {
      const filePath = this.allLnks[i].href
      this.playlistLinks[i] = filePath.match(/([^\/]+)(?=\.\w+$)/)[0]
      this.allLnks[i].onclick = (e) => {
        this.index = i
        e.preventDefault()
        for (let i = 0; i < this.linkNum; i++) {
          this.allLnks[i].classList.remove('is-current')
        }
        console.log(this.index)
        this.play(this.index)
      }
    }
  }

  onMouseEnter() {
    this.video.setAttribute('controls', 'true')
  }

  onMouseLeave() {
    this.video.removeAttribute('controls')
  }

  onEnded() {
    this.allLnks[this.current].classList.remove('is-current')
    if (this.current + 1 >= this.linkNum) {
      this.nextVid = 0
    } else {
      this.nextVid = this.current + 1
    }
    this.play(this.nextVid)
  }

  addEventListeners() {
    this.video.addEventListener('ended', this.onEnded.bind(this), {
      passive: true,
    })

    this.video.addEventListener('mouseenter', this.onMouseEnter.bind(this), {
      passive: true,
    })

    this.video.addEventListener('mouseleave', this.onMouseLeave.bind(this), {
      passive: true,
    })

    // let focusedLink = document.activeElement
    // this.index = indexOf.call(this.allLnks, focusedLink)

    // document.addEventListener('keydown', function (e) {
    //   if (this.index) {
    //     let focusedElement = document.activeElement
    //     if (e.keyCode == 40 || e.keyCode == 39) {
    //       // down or right cursor
    //       let nextNode = focusedElement.nextElementSibling
    //       if (nextNode) {
    //         nextNode.focus()
    //       } else {
    //         this.videoPlaylist.firstElementChild.focus()
    //       }
    //     }
    //     if (e.keyCode == 38 || e.keyCode == 37) {
    //       // up or left cursor
    //       let previousNode = focusedElement.previousElementSibling
    //       if (previousNode) {
    //         previousNode.focus()
    //       } else {
    //         this.videoPlaylist.lastElementChild.focus()
    //       }
    //     }
    //   }
    // })
  }

  play(index) {
    this.videoPlaylist.children[index].classList.add('is-current')
    this.source[1].src = this.path + this.playlistLinks[index] + '.webm'
    this.source[0].src = this.path + this.playlistLinks[index] + '.mp4'
    console.log(this.source[0].src, this.source[1].src, 'source')
    this.current = this.index
    this.video.load()
    this.video.play()
  }
}
