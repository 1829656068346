// import gsap from 'gsap'
// import { CustomEase } from 'gsap/CustomEase'

// gsap.registerPlugin(CustomEase)
// CustomEase.create('expoEase', '0,.62,0,.99')
// CustomEase.create('easeInOut', '0.075, 0.82, 0.165, 1')

export default class DropDown {
  constructor() {
    this.elements = document.querySelectorAll('.dropdown')
  }

  // show(list) {
  //   console.log(list, 'list')
  //   const elem = list
  //   this.showTl.to(elem, {
  //     duration: 0.3,
  //     autoAlpha: 1,
  //     y: 0,
  //   })
  //   return this.showTl
  // }

  // hide(list) {
  //   console.log(list, 'list')
  //   const elem = list
  //   this.hideTl.to(elem, {
  //     duration: 0.3,
  //     autoAlpha: 0,
  //     y: 15,
  //   })
  //   return this.hideTl
  // }

  /**
   * Events
   */

  // onEnter(e) {
  //   let list = e.currentTarget
  //   list.classList.add('is-active')
  //   console.log(list.children, 'child')

  //   // gsap.to(list, {
  //   //   duration: 0.4,
  //   //   autoAlpha: 1,
  //   //   y: 0,
  //   //   onStart: () => {
  //   //     this.isOpen = true
  //   //   },
  //   // })
  // }

  // onResize(elements) {}

  // onLeave(e) {
  //   let list = e.currentTarget
  //   list.classList.remove('is-active')
  //   // gsap.to(list, {
  //   //   duration: 0.4,
  //   //   autoAlpha: 0,
  //   //   y: 15,
  //   //   onComplete: () => {
  //   //     this.isOpen = false
  //   //   },
  //   // })
  // }

  onToggle(e) {
    let list = e.currentTarget
    list.classList.toggle('is-active')
  }

  addEventsListeners() {
    for (let i = 0; i < this.elements.length; i++) {
      if ('ontouchstart' in document) {
        this.elements[i].addEventListener(
          'touchstart',
          this.onToggle.bind(this),
          {
            passive: true,
          }
        )
      } else {
        this.elements[i].addEventListener(
          'mousedown',
          this.onToggle.bind(this),
          {
            passive: true,
          }
        )
      }
    }
  }

  removeEventsListeners() {
    for (let i = 0; i < this.elements.length; i++) {
      if ('ontouchstart' in document) {
        this.elements[i].removeEventListener(
          'touchstart',
          this.onToggle.bind(this)
        )
      } else {
        this.elements[i].removeEventListener(
          'mousedown',
          this.onToggle.bind(this)
        )
      }
    }
  }
}
