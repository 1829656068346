import { Transform } from 'ogl'
import map from 'lodash/map'
import gsap from 'gsap'

import Media from './Media'

export default class Gallery {
  constructor({ element, geometry, gl, scene, viewport }) {
    this.element = element
    this.geometry = geometry
    this.gl = gl
    this.scene = scene
    this.viewport = viewport

    this.galleryElement = document.querySelector('.top__gallery')

    this.group = new Transform()

    this.x = {
      current: 0,
      target: 0,
      lerp: 0.1,
    }

    this.y = {
      current: 0,
      target: 0,
      lerp: 0.1,
    }

    this.scroll = {
      x: 0,
      y: 0,
      current: 0,
      last: 0,
    }

    this.groupPos = {
      x: 0,
      y: 0,
      current: 0,
      target: 0,
      last: 0,
    }

    this.speed = {
      current: 0,
      target: 0,
      lerp: 0.16,
    }

    this.scrollCurrent = {
      x: 0,
      y: 0,
    }

    this.createMedias()

    this.onResize({
      viewport: this.viewport,
    })

    this.group.setParent(scene)
  }

  createMedias() {
    this.mediasElements = document.querySelectorAll('.top__gallery__media')

    this.medias = map(this.mediasElements, (element) => {
      return new Media({
        element,
        geometry: this.geometry,
        gl: this.gl,
        scene: this.group,
        viewport: this.viewport,
        gallerySizes: this.gallerySizes,
      })
    })
  }

  createGeometry() {
    this.geometry = new Plane(this.gl, {
      widthSegments: 20,
      heightSegments: 20,
    })
  }

  /**
   * Animations
   */
  show() {
    map(this.medias, (media) => media.show())
  }

  hide() {
    map(this.medias, (media) => media.hide())
  }

  /**
   * Events
   */
  onResize(e) {
    this.bounds = this.galleryElement.getBoundingClientRect()

    this.viewport = e.viewport
    // console.log(this.viewport, 'this.viewport')

    // ギャラリーサイズ取得
    this.gallerySizes = {
      width: this.bounds.width,
      height: this.bounds.height,
    }

    this.scroll.x = this.x.target = 0
    this.scroll.y = this.y.target = 0

    map(this.medias, (media) => {
      media.onResize(e, this.gallerySizes, this.scroll)
    })

    // console.log(this.bounds, 'this.bounds')
    // console.log(this.gallerySizes, 'this.gallerySizes')
  }

  onTouchDown({ x, y }) {
    this.speed.target = 1

    // this.scrollCurrent.x = this.scroll.current

    this.scrollCurrent.x = this.scroll.x
    // this.scrollCurrent.y = this.scroll.y
  }
  onTouchMove({ x, y }) {
    const xDistance = x.start - x.end
    this.x.target = this.scrollCurrent.x - xDistance
    // this.y.target = this.scrollCurrent.y - yDistance
    // console.log(this.x.target, this.y.target, 'target')
  }
  onTouchUp({ x, y }) {
    this.speed.target = 0
  }

  onWheel(pixelX, pixelY) {
    this.x.target += pixelY
    // this.y.target += pixelY
    // console.log(this.scroll.current, 'this.scroll.current onWheel')
    // console.log(this.group.position, 'this.group.position onWheel')
  }
  /**
   * Loop
   */
  update(scroll) {
    this.scroll.current = scroll.current

    this.x.target += 0.5

    // Infinite scroll
    this.speed.current = gsap.utils.interpolate(
      this.speed.current,
      this.speed.target,
      this.speed.lerp
    )

    this.x.current = gsap.utils.interpolate(
      this.x.current,
      this.x.target,
      this.x.lerp
    )

    if (this.scroll.x < this.x.current) {
      this.x.direction = 'right'
    } else if (this.scroll.x > this.x.current) {
      this.x.direction = 'left'
    }

    this.scroll.x = this.x.current

    if (this.medias) {
      map(this.medias, (media, index) => {
        const scaleX = media.mesh.scale.x / 2
        const offsetX = this.viewport.width * 0.5

        // elementが画面外に出た場合
        if (this.x.direction === 'left') {
          const x = media.mesh.position.x + scaleX

          if (x < -offsetX) {
            media.extra.x += this.gallerySizes.width
          }
        } else if (this.x.direction === 'right') {
          const x = media.mesh.position.x - scaleX

          if (x > offsetX) {
            media.extra.x -= this.gallerySizes.width
          }
        }

        media.update(this.scroll, this.speed.current)
      })
    }

    // map(this.medias, (media) => {
    //   const scaleX = media.mesh.scale.x / 2
    //   const offsetX = this.viewport.width * 0.6

    //   // elementが画面外に出た場合
    //   if (this.scroll.direction === 'left') {
    //     const x = media.mesh.position.x + scaleX

    //     if (x < -offsetX) {
    //       media.extra.x += this.gallerySizes.width
    //     }
    //   } else if (this.scroll.direction === 'right') {
    //     const x = media.mesh.position.x - scaleX

    //     if (x > offsetX) {
    //       media.extra.x -= this.gallerySizes.width
    //     }
    //   }

    //   media.update(this.scroll, this.speed.current)
    // })

    // スクロール時にgroupを動かす
    this.group.position.y = scroll.current
  }
  destroy() {
    this.scene.removeChild(this.group)
  }
}
