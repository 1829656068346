import { Camera, Renderer, Transform } from 'ogl'

import Top from './Top'
import Sub from './Sub'
import Info from './Info'

// import Transition from './Transition'

export default class Canvas {
  constructor({ template }) {
    this.template = template

    this.x = {
      start: 0,
      distance: 0,
      end: 0,
    }

    this.y = {
      start: 0,
      distance: 0,
      end: 0,
    }

    this.screen = {
      width: window.innerWidth,
      height: window.innerHeight,
    }

    this.cameraPos = 5
    this.cameraAsp = this.screen.width / this.screen.height
    this.cameraFov =
      2 * Math.atan(this.screen.height / (2 * this.cameraPos)) * (180 / Math.PI)
    // this.fov = this.cameraFov * (Math.PI / 180)
    this.height = 2 * Math.tan(this.cameraFov / 2) * this.cameraPos
    this.width = this.height * this.cameraAsp

    this.viewport = {
      width: this.width,
      height: this.height,
    }

    this.createRenderer()
    this.createCamera()
    this.createScene()

    this.onResize()
  }
  createRenderer() {
    this.renderer = new Renderer({
      alpha: true,
      antialias: true,
    })

    this.gl = this.renderer.gl

    this.container = document.querySelector('.canvas__container')
    this.container.appendChild(this.gl.canvas)
  }

  createCamera() {
    this.camera = new Camera(this.gl)
    this.camera.fov = this.cameraFov
    this.camera.aspect = this.cameraAspect
    this.camera.position.z = this.cameraPos
  }

  createScene() {
    this.scene = new Transform()
  }

  createTop() {
    this.top = new Top({
      gl: this.gl,
      screen: this.screen,
      scene: this.scene,
      viewport: this.viewport,
      transition: this.transition,
    })
    this.top.preloadAssets()
  }

  destroyTop() {
    if (!this.top) return
    this.top.destroy()
    this.top = null
  }

  createSub() {
    this.sub = new Sub({
      gl: this.gl,
      scene: this.scene,
      viewport: this.viewport,
    })
  }

  destroySub() {
    if (!this.sub) return
    this.sub.destroy()
    this.sub = null
  }

  createInfo() {
    this.info = new Info({
      gl: this.gl,
      scene: this.scene,
      viewport: this.viewport,
    })
  }

  destroyInfo() {
    if (!this.info) return
    this.info.destroy()
    this.info = null
  }

  // createModal() {
  //   this.modal = new Modal({
  //     gl: this.gl,
  //     scene: this.scene,
  //     viewport: this.viewport,
  //     transition: this.transition,
  //   })
  // }

  // destroyModal() {
  //   if (!this.modal) return
  //   this.modal.destroy()
  //   this.modal = null
  // }

  createCarouel() {
    this.carouel = new Carouel({
      gl: this.gl,
      scene: this.scene,
      viewport: this.viewport,
      transition: this.transition,
    })
  }

  destroyCarouel() {
    if (!this.carouel) return
    this.carouel.destroy()
    this.carouel = null
  }
  /**
   * Events
   */
  onPreloaded() {
    this.onChangeEnd(this.template)
  }

  onChangeStart(template, url) {
    if (this.top) {
      this.top.hide()
    }

    if (this.sub) {
      this.sub.hide()
    }

    if (this.info) {
      this.info.hide()
    }

    if (this.modal) {
      this.modal.hide()
    }

    // this.toModal = this.template === 'top' || 'sub'
    // this.fromModal = this.template === 'modal'

    // if (this.toModal || this.fromModal) {
    //   this.transition = new Transition({
    //     template: this.template,
    //     gl: this.gl,
    //     scene: this.scene,
    //     viewport: this.viewport,
    //     url,
    //   })

    //   this.transition.setElement(this.top || this.modal)
    // }
  }

  onChangeEnd(template) {
    if (template === 'top') {
      this.createTop()
    } else {
      this.destroyTop()
    }

    if (template === 'sub') {
      this.createSub()
    } else if (this.sub) {
      this.destroySub()
    }

    if (template === 'info') {
      this.createInfo()
    } else if (this.info) {
      this.destroyInfo()
    }

    if (template === 'modal') {
      this.createModal()
    } else if (this.modal) {
      this.destroyModal()
    }

    this.template = template
    // console.log(this.template, 'template')
  }

  onResize() {
    // ウィンドウサイズ
    this.screen = {
      width: window.innerWidth,
      height: window.innerHeight,
    }

    this.renderer.setSize(this.screen.width, this.screen.height)
    this.camera.perspective({
      aspect: this.screen.width / this.screen.height,
      // aspect: this.gl.canvas.width / this.gl.canvas.height,
    })

    // cameraアスペクト・Fov再計算
    this.cameraAsp = this.screen.width / this.screen.height
    this.cameraFov =
      2 * Math.atan(this.screen.height / (2 * this.cameraPos)) * (180 / Math.PI)

    this.camera.fov = this.cameraFov

    // viewportサイズ計算
    this.fov = this.cameraFov * (Math.PI / 180)
    this.height = 2 * Math.tan(this.fov / 2) * this.cameraPos
    this.width = this.height * this.cameraAsp

    this.viewport = {
      width: this.width,
      height: this.height,
      lastWidth: null,
    }
    const values = {
      viewport: this.viewport,
    }

    // console.log(values, 'values')

    if (this.top) {
      this.top.onResize(values)
    }

    if (this.sub) {
      this.sub.onResize(values)
    }

    if (this.info) {
      this.info.onResize(values)
    }

    if (this.modal) {
      this.modal.onResize(values)
    }
  }

  onTouchDown(e) {
    this.isDown = true
    this.x.start = e.touches ? e.touches[0].clientX : e.clientX
    this.y.start = e.touches ? e.touches[0].clientY : e.clientY

    const values = {
      x: this.x,
      y: this.y,
    }

    if (this.top) {
      this.top.onTouchDown(values)
    }

    if (this.sub) {
      this.sub.onTouchDown(values)
    }

    if (this.info) {
      this.info.onTouchDown(values)
    }

    if (this.modal) {
      this.modal.onTouchDown(values)
    }
  }

  onTouchMove(e) {
    if (!this.isDown) return
    this.x.end = e.touches ? e.touches[0].clientX : e.clientX
    this.y.end = e.touches ? e.touches[0].clientY : e.clientY

    const values = {
      x: this.x,
      y: this.y,
    }

    if (this.top) {
      this.top.onTouchMove(values)
    }

    if (this.sub) {
      this.sub.onTouchMove(values)
    }

    if (this.info) {
      this.info.onTouchMove(values)
    }

    if (this.modal) {
      this.modal.onTouchMove(values)
    }
  }

  onTouchUp(e) {
    this.isDown = false
    this.x.end = e.touches ? e.changedTouches[0].clientX : e.clientX
    this.y.end = e.touches ? e.changedTouches[0].clientY : e.clientY

    const values = {
      x: this.x,
      y: this.y,
    }

    if (this.top) {
      this.top.onTouchUp(values)
    }

    if (this.sub) {
      this.sub.onTouchUp(values)
    }

    if (this.info) {
      this.info.onTouchUp(values)
    }

    if (this.modal) {
      this.modal.onTouchUp(values)
    }
  }

  onWheel(e) {
    if (this.top) {
      this.top.onWheel(e)
    }
  }
  /**
   * Loop
   */
  update(scroll) {
    if (this.top) {
      this.top.update(scroll)
    }

    if (this.sub) {
      this.sub.update()
    }

    if (this.info) {
      this.info.update()
    }

    if (this.modal) {
      this.modal.update()
    }

    this.renderer.render({
      camera: this.camera,
      scene: this.scene,
    })
  }
}
