import Component from '../classes/Component'
import ModalToggle from './ModalToggle'
import Prefix from 'prefix'

import gsap from 'gsap'
import { CustomEase } from 'gsap/CustomEase'

gsap.registerPlugin(CustomEase)
CustomEase.create('expoEase', '0,.62,0,.99')
CustomEase.create('easeInOut', '0.075, 0.82, 0.165, 1')

export default class Navigation extends Component {
  constructor({ template }) {
    super({
      element: '.nav',
      elements: {
        // items: '.nav__item',
        trigger: '.nav__trigger__btn',
        modal: '.nav__modal',
        wrapper: '.nav__modal__wrapper',
        main: '.nav__modal__main',
        sub: '.nav__modal__sub',
        links: '.nav__modal__link',
      },
    })

    this.body = document.body
    this.modal = this.elements.modal
    this.wrapper = this.elements.wrapper
    this.main = this.elements.main
    this.sub = this.elements.sub
    this.triggerBtn = this.elements.trigger
    this.links = [...this.elements.links]

    this.isOpen = false
    this.duration = 0.4

    this.scroll = {
      current: 0,
      target: 0,
      last: 0,
      limit: 0,
      lerp: 0.1,
      start: 0,
      end: 0,
    }

    this.transformPrefix = Prefix('transform')

    this.createToggle()
    this.createTween()
    // this.onChange(template)

    this.triggerBtn.addEventListener('click', this.onClickTrigger.bind(this), {
      passive: true,
    })

    for (var i = 0; i < this.links.length; i++) {
      this.links[i].addEventListener(
        'click',
        this.onClickLinks.bind(this).bind(this),
        {
          passive: true,
        }
      )
    }
  }

  createToggle() {
    this.modalToggle = new ModalToggle()
  }

  onClickTrigger() {
    if (this.showTl.isActive() || this.hideTl.isActive()) return

    if (!this.isOpen) {
      this.triggerBtn.classList.add('is-active')
      this.showTl.restart()
    }

    if (this.isOpen) {
      this.triggerBtn.classList.remove('is-active')
      this.hideTl.restart()
    }
  }

  onClickLinks() {
    if (this.showTl.isActive() || this.hideTl.isActive()) return

    if (this.isOpen) {
      this.hideTl.restart()
    }
  }

  create() {
    super.create()

    // for (let i = 0; i < this.elements.links.length; i++) {
    //   const element = this.elements.links[i]
    //   this.MagneticLink = new MagneticLink(element)
    // }
  }

  createTween() {
    this.show()
    this.hide()
  }

  show() {
    // メニュー展開
    this.showTl = gsap.timeline({
      paused: true,
      ease: 'expoEase',
      onStart: () => {
        this.onResize()
        this.isOpen = true
      },
    })
    this.showTl
      .set([this.main, this.sub], {
        autoAlpha: 0,
        y: 10,
      })

      .to(['.header__nav'], {
        duration: this.duration,
        autoAlpha: 0,
        y: -10,
      })
      .to(
        this.modal,
        {
          duration: this.duration,
          autoAlpha: 1,
        },
        '<'
      )
      .to(
        [this.main, this.sub],
        {
          duration: this.duration,
          autoAlpha: 1,
          y: 0,
        },
        '<0.2'
      )
  }

  hide() {
    // this.scroll.target = 0

    // メニュー格納
    this.hideTl = gsap.timeline({
      ease: 'expoEase',
      paused: true,
      onStart: () => {},
      onComplete: () => {
        this.isOpen = false
      },
    })

    this.hideTl
      .to([this.main, this.sub], {
        duration: this.duration,
        autoAlpha: 0,
        y: 10,
      })
      .to(
        this.modal,
        {
          duration: this.duration,
          autoAlpha: 0,
        },
        '<0.2'
      )
      .to(
        ['.header__nav'],
        {
          duration: this.duration,
          autoAlpha: 1,
          y: 0,
        },
        '<'
      )
  }

  onResize() {
    if (this.wrapper) {
      this.scroll.limit =
        this.wrapper.offsetHeight -
        window.innerHeight +
        window.innerHeight * 0.2
    }
  }

  onChange(template) {
    // gsap.to(this.element, {
    //   color:
    //     template === 'about'
    //       ? COLOR_BRIGHT_GRAY
    //       : COLOR_QUATQUATER_SPANISH_WHITE,
    //   duration: 1.5,
    // })
    // if (template === 'sub') {
    //   gsap.to(this.element, {
    //     color: COLOR_WHITE,
    //     duration: 1.5,
    //   })
    //   gsap.to(this.elements.items[0], {
    //     autoAlpha: 1,
    //     delay: 0.75,
    //     duration: 0.75,
    //   })
    //   gsap.to(this.elements.items[1], {
    //     autoAlpha: 0.5,
    //     duration: 0.75,
    //   })
    // } else {
    //   gsap.to(this.element, {
    //     color: COLOR_WHITE,
    //     duration: 1.5,
    //   })
    //   gsap.to(this.elements.items[0], {
    //     autoAlpha: 0,
    //     duration: 0.75,
    //   })
    //   gsap.to(this.elements.items[1], {
    //     autoAlpha: 1,
    //     delay: 0.75,
    //     duration: 0.75,
    //   })
    // }
    // console.log(template)
  }

  onTouchDown(e) {
    if (this.isOpen && 'ontouchstart' in document) {
      this.isDown = true
      this.scroll.start = e.touches ? e.touches[0].clientY : e.clientY
    }
  }
  onTouchMove(e) {
    if (this.isOpen && 'ontouchstart' in document) {
      if (!this.isDown) return
      this.scroll.end = e.touches ? e.touches[0].clientY : e.clientY

      this.scroll.target += (this.scroll.start - this.scroll.end) * 2
      this.scroll.start = this.scroll.end

      this.scroll.target = gsap.utils.clamp(
        0,
        this.scroll.limit,
        this.scroll.target
      )
    }
  }
  onTouchUp() {
    if (this.isOpen && 'ontouchstart' in document) {
      this.isDown = false
    }
  }

  onWheel({ pixelY }) {
    if (this.isOpen) {
      this.scroll.target += pixelY
    }
  }

  update() {
    if (this.isOpen && this.scroll.limit) {
      this.scroll.target = gsap.utils.clamp(
        0,
        this.scroll.limit,
        this.scroll.target
      )

      this.scroll.current = gsap.utils.interpolate(
        this.scroll.current,
        this.scroll.target,
        this.scroll.lerp
      )

      if (this.scroll.current < 0.01) {
        this.scroll.current = 0
      }

      if (this.wrapper) {
        this.wrapper.style[
          this.transformPrefix
        ] = `translateY(-${this.scroll.current}px)`
      }
    }
  }

  addEventListeners() {}
}
