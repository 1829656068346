export default class Toggle {
  constructor(elements) {
    this.elements = elements

    for (var i = 0; i < this.elements.length; i++) {
      const btn = this.elements[i]
      const parent = btn.parentNode
      const child = btn.nextElementSibling
      const content = child.children[0]

      if (parent.classList.contains('is-open')) {
        child.style.maxHeight = content.scrollHeight + 'px'
      }
    }
    // this.isDown = false
  }

  onResize(elements) {
    // console.log(this.elements, 'toggle')
    for (var i = 0; i < elements.length; i++) {
      const btn = elements[i]
      const parent = btn.parentNode
      const child = btn.nextElementSibling
      const content = child.children[0]
      if (parent.classList.contains('is-open')) {
        child.style.maxHeight = content.scrollHeight + 'px'
      }
    }
  }

  onTrigger(e) {
    const element = e.currentTarget
    const parent = element.parentNode
    const child = element.nextElementSibling
    const content = child.children[0]
    this.height = content.scrollHeight

    // if (this.isDown) return
    // this.isDown = true

    if (parent.classList.contains('is-open')) {
      parent.classList.remove('is-open')

      child.style.maxHeight = null
      this.isOpen = false
    } else {
      parent.classList.add('is-open')

      child.style.maxHeight = this.height + 'px'
      this.isOpen = true
    }

    console.log(this.isOpen, 'isOpen')

    // setTimeout(() => {
    //   this.isDown = false
    // }, 1000)
  }

  addEventsListeners() {}

  removeEventsListeners() {}
}
