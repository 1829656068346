import gsap from 'gsap'

export default class SectionScroll {
  constructor(wrapper) {
    this.wrapper = wrapper

    this.container = document.querySelector('.pagination__container')

    this.elements = [...this.wrapper.children]
    this.elementsLength = this.elements.length
    this.footerElem = this.wrapper.querySelector('footer')
    this.footer = this.footerElem.classList.add('top__footer')

    this.btns = []
    this.scroll = {
      start: 0,
      current: 0,
      state: false,
    }

    this.createPagination()
  }

  createPagination() {
    this.pagination = document.createElement('ul')
    this.pagination.classList.add('pagination__list')

    for (let i = 0; i < this.elementsLength; i++) {
      const btn = document.createElement('li')
      btn.classList.add('pagination__item')
      btn.dataset.page = i
      this.btns.push(btn)
      this.pagination.appendChild(btn)
    }

    this.btns[0].classList.add('is-current')
    this.container.appendChild(this.pagination)
    // this.container.style.opacity = 1
  }

  destroyPagination() {
    if (this.pagination) {
      this.container.removeChild(this.pagination)
    }
  }

  show() {
    if (this.btns.length === this.elementsLength) {
      gsap.to(this.container, {
        ease: 'ease',
        duration: 0.6,
        autoAlpha: 1,
        delay: 0.4,
      })
    }
  }

  // hide() {
  //   gsap.to(this.container, {
  //     duration: 0.6,
  //     autoAlpha: 0,
  //   })
  // }

  move(index) {
    // console.log(index, 'index')
    if (index != this.scroll.current && !this.scroll.state) {
      this.scroll.state = true
      setTimeout(() => {
        this.scroll.state = false
      }, 500)

      this.btns[this.scroll.current].classList.remove('is-current')
      this.scroll.current = index
      this.btns[this.scroll.current].classList.add('is-current')

      if (this.scroll.current < this.elementsLength) {
        for (let i = 0; i < this.elementsLength; i++) {
          this.elements[i].style.transform = `translateY(${
            this.scroll.current * -100
          }vh)`
        }
      }
      // else {
      //   for (let i = 0; i < this.elementsLength; i++) {
      //     this.elements[i].style.transform = `translateY(${
      //       (this.elementsLength - 1) * -100 - this.footerVh
      //     }vh)`
      //   }
      // }
    }
  }

  next() {
    // console.log('next')
    this.scroll.current < this.elementsLength - 1
      ? this.move(this.scroll.current + 1)
      : false
  }

  prev() {
    // console.log('prev')
    this.scroll.current > 0 ? this.move(this.scroll.current - 1) : false
  }

  // onResize() {
  //   this.footerH = this.footerElem.clientHeight
  //   this.footerVh = (100 / window.innerHeight) * this.footerH
  //   console.log(this.footerVh)
  // }
  onBtns() {
    console.log('onBtns')
    this.isHovering = true
  }

  onClick(e) {
    console.log(e.target.dataset.page, 'e.target.dataset.page')

    this.move(parseInt(e.target.dataset.page))
  }

  onTouchDown(startY) {
    this.startY = startY
  }

  onTouchMove(endY) {
    this.endY = endY
    if (this.startY && this.endY) {
      this.startY < this.endY ? this.prev() : this.next()
    }
  }

  onTouchUp(e) {}

  onWheel({ pixelY }) {
    this.delta = -pixelY
    // console.log(pixelY, 'delta')

    this.delta > 0 ? this.prev() : this.next()
  }

  addEventsListeners() {
    if (this.btns) {
      for (let i = 0; i < this.btns.length; i++) {
        const btn = this.btns[i]
        btn.addEventListener('click', this.onClick.bind(this), {
          passive: true,
        })
        btn.addEventListener('mouseenter', this.onBtns.bind(this), {
          passive: true,
        })
      }
    }
  }

  removeEventsListeners() {
    if (this.btns) {
      for (let i = 0; i < this.btns.length; i++) {
        const btn = this.btns[i]
        btn.removeEventListener('click', this.onClick.bind(this))
        btn.removeEventListener('mouseenter', this.onBtns.bind(this))
      }
    }
  }

  destroy() {
    this.removeEventsListeners()
    this.destroyPagination()
  }
}
