import gsap from 'gsap'
import Prefix from 'prefix'

export default class InertiaScroll {
  constructor(scroll, wrapper) {
    this.scroll = scroll
    this.wrapper = wrapper

    this.container = document.querySelector('.progress__container')
    // スクロールのクロスブラウザ対応
    this.transformPrefix = Prefix('transform')

    this.createProgressBar()
  }

  createProgressBar() {
    this.bar = document.createElement('div')
    this.bar.classList.add('progress__bar')

    this.barState = document.createElement('div')
    this.barState.classList.add('progress__bar__state')
    this.bar.appendChild(this.barState)

    this.container.appendChild(this.bar)
    // this.container.style.opacity = 1
  }

  destroyProgressBar() {
    if (this.bar) {
      this.container.removeChild(this.bar)
    }
  }

  show() {
    // if (this.btns.length === this.elementsLength) {
    gsap.to(this.container, {
      ease: 'ease',
      duration: 0.6,
      autoAlpha: 1,
      delay: 0.4,
    })
    // }
  }

  hide() {
    gsap.to(this.container, {
      duration: 0.6,
      autoAlpha: 0,
    })
  }

  onResize() {
    // console.log(this.wrapper, 'inertiaScroll')
    if (this.wrapper) {
      this.scroll.limit = this.wrapper.offsetHeight - window.innerHeight
    }
  }

  onWheel({ pixelY }) {
    this.scroll.target += pixelY
    // console.log(this.scroll.target)
  }

  update(y) {
    // Inertia scroll
    this.scroll.target = gsap.utils.clamp(
      0,
      this.scroll.limit,
      this.scroll.target
    )

    this.scroll.current = gsap.utils.interpolate(
      this.scroll.current,
      this.scroll.target,
      this.scroll.lerp
    )

    if (this.scroll.current < 0.01) {
      this.scroll.current = 0
    }

    if (this.wrapper) {
      this.wrapper.style[
        this.transformPrefix
      ] = `translateY(-${this.scroll.current}px)`
    }

    // Progress bar
    this.progress = this.scroll.current / this.scroll.limit
    this.barState.style[this.transformPrefix] = `scaleY(${
      0.2 + this.progress * 0.8
    })`
  }

  destroy() {
    this.destroyProgressBar()
  }
}
