import { lerp, clamp } from '../utils/math'

export default class DragCarousel {
  constructor(obj) {
    this.element = document.querySelector('.carousel')
    this.wrappper = this.element.querySelector('.carousel__wrapper')
    this.items = this.element.querySelectorAll('.carousel__link')
    this.titles = this.element.querySelectorAll('.card__text')
    this.bar = document.querySelector('.carousel__progress__bar')

    this.progress = 0
    this.speed = 0
    this.oldX = 0
    this.x = 0
    this.playrate = 0

    this.onResize()
  }

  onResize() {
    this.bounds = this.element.getBoundingClientRect()
    this.progress = 0
    this.wrapWidth = this.items[0].clientWidth * this.items.length
    this.wrappper.style.width = `${this.wrapWidth}px`
    this.maxScroll = this.wrapWidth - this.element.clientWidth
  }

  onClick(e) {
    if ('ontouchstart' in document) {
      e.preventDefault()
    }
  }

  onWheel({ pixelY }) {
    // this.progress += pixelY
    // this.move()
    // if (pixelY >= this.bounds.top + pixelY) {
    // }
  }

  onTouchDown(e) {
    this.isDragging = true
    console.log(this.isDragging, 'this.isDragging')
    this.element.classList.add('is-dragging')
    // if (this.isDragging) {
    //   e.preventDefault()
    // }
    this.startX = e.clientX || e.touches[0].clientX
  }

  onTouchMove(e) {
    e.preventDefault()
    if (!this.isDragging) return false
    const x = e.clientX || e.touches[0].clientX
    this.progress += (this.startX - x) * 2.5
    this.startX = x
    this.move()
  }

  onTouchUp(e) {
    this.isDragging = false
    console.log(this.isDragging, 'this.isDragging')
    this.element.classList.remove('is-dragging')
  }

  move() {
    this.progress = clamp(this.progress, 0, this.maxScroll)
  }

  update() {
    this.x = lerp(this.x, this.progress, 0.1)

    if (this.x < 0.01) {
      this.x = 0
    }

    this.playrate = this.x / this.maxScroll
    //

    this.wrappper.style.transform = `translateX(${-this.x}px)`
    this.bar.style.transform = `scaleX(${0.18 + this.playrate * 0.82})`
    //
    this.speed = Math.min(100, this.oldX - this.x)
    this.oldX = this.x
    //
    this.scale = lerp(this.scale, this.speed, 0.1)
    this.items.forEach((i) => {
      i.style.transform = `scale(${1 - Math.abs(this.speed) * 0.002})`
      i.style.transform = `scaleX(${1 + Math.abs(this.speed) * -0.004})`
    })
  }

  addEventsListeners() {
    if ('ontouchstart' in document) {
      this.wrappper.addEventListener(
        'touchstart',
        this.onTouchDown.bind(this),
        {
          passive: false,
        }
      )
      this.wrappper.addEventListener('touchmove', this.onTouchMove.bind(this), {
        passive: false,
      })
      this.wrappper.addEventListener('touchend', this.onTouchUp.bind(this), {
        passive: true,
      })
    } else {
      this.wrappper.addEventListener('mousedown', this.onTouchDown.bind(this), {
        passive: false,
      })
      this.wrappper.addEventListener('mousemove', this.onTouchMove.bind(this), {
        passive: false,
      })
      this.wrappper.addEventListener('mouseup', this.onTouchUp.bind(this), {
        passive: true,
      })
    }
  }

  removeEventsListeners() {
    if ('ontouchstart' in document) {
      this.wrappper.removeEventListener(
        'touchstart',
        this.onTouchDown.bind(this)
      )
      this.wrappper.removeEventListener(
        'touchmove',
        this.onTouchMove.bind(this)
      )
      this.wrappper.removeEventListener('touchend', this.onTouchUp.bind(this))
    } else {
      this.wrappper.removeEventListener(
        'mousedown',
        this.onTouchDown.bind(this)
      )
      this.wrappper.removeEventListener(
        'mousemove',
        this.onTouchMove.bind(this)
      )
      this.wrappper.removeEventListener('mouseup', this.onTouchUp.bind(this))
    }
  }
}
