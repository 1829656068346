import userAgent from '../classes/userAgent.js'
const UAParser = require('ua-parser-js')
import each from 'lodash/each'
import map from 'lodash/map'

import gsap from 'gsap'
import { CustomEase } from 'gsap/CustomEase'

gsap.registerPlugin(CustomEase)
// CustomEase.create('expoEase', '0,.62,0,.99')
// CustomEase.create('easeInOut', '0.075, 0.82, 0.165, 1')
CustomEase.create('smoothEase', '0.2, 1, 0.24, 1')

// import Title from '../animations/Title'
// import Paragraph from '../animations/Paragraph'
import Module from '../animations/Module'
import AsyncLoad from '../classes/AsyncLoad'

export default class Page {
  constructor({ element, elements, id }) {
    this.selector = element
    this.selectorChildren = {
      ...elements,
      header: 'header',
      animationsModules: '.js-scroll',
      // animationsTitles: '[data-animation="title"]',
      // animationsParagraphs: '[data-animation="paragraph"]',

      preloaders: '.js-lazy',
      // preloaders: '[data-src]',
    }

    this.scroll = {
      current: 0,
      target: 0,
      last: 0,
      limit: 0,
      lerp: 0.1,
    }

    this.id = id

    this.isLoaded = false
  }

  create() {
    // 各ページクラスの要素をコンストラクタで渡してDom取得
    this.element = document.querySelector(this.selector)
    this.elements = {}

    each(this.selectorChildren, (entry, key) => {
      // console.log(entry, 'entry')
      if (
        entry instanceof window.HTMLElement ||
        entry instanceof window.NodeList ||
        Array.isArray(entry)
      ) {
        this.elements[key] = entry
      } else {
        this.elements[key] = document.querySelectorAll(entry)
        if (this.elements[key].length === 0) {
          this.elements[key] = null
        } else if (this.elements[key].length === 1) {
          this.elements[key] = document.querySelector(entry)
        }
      }
    })
    // console.log('Create', this.element, this.elements)
    this.createAnimations()
    this.createPreloader()
  }

  createAnimations() {
    this.animations = []

    // // Titles
    // this.animationsTitles = map(this.elements.animationsTitles, (element) => {
    //   return new Title({
    //     element,
    //   })
    // })

    // this.animations.push(...this.animationsTitles)

    // // Paragraphs
    // this.animationsParagraphs = map(
    //   this.elements.animationsParagraphs,
    //   (element) => {
    //     return new Paragraph({
    //       element,
    //     })
    //   }
    // )
    // this.animations.push(...this.animationsParagraphs)

    // Modules
    this.animationsModules = map(this.elements.animationsModules, (element) => {
      return new Module({
        element,
      })
    })
    this.animations.push(...this.animationsModules)
  }

  createPreloader() {
    this.preloaders = map(this.elements.preloaders, (element) => {
      return new AsyncLoad({ element })
    })
  }

  show() {
    return new Promise((resolve) => {
      this.animateIn = gsap.timeline({
        ease: 'smoothEase',
        // delay: 2,
        // onStart: () => {
        //   this.onResize()
        // },
        // onComplete: () => {
        //   this.addEventsListeners()
        //   resolve()
        // },
      })

      this.animateIn
        .set(this.scroll, {
          current: 0,
          target: 0,
        })
        .fromTo(
          this.element,
          {
            autoAlpha: 0,
          },
          {
            duration: 1.5,
            autoAlpha: 1,
          },
          '-=0.5'
        )

      this.animateIn.call((_) => {
        this.addEventsListeners()
        resolve()
      })
    })
  }

  hide() {
    return new Promise((resolve) => {
      this.destroy()
      // 下層ロード時のコンテンツ高さ制御
      this.isLoaded = false

      this.animateOut = gsap.timeline({
        ease: 'smoothEase',
        onComplete: resolve,
      })

      this.animateOut.to(this.element, {
        duration: 1.5,
        autoAlpha: 0,
      })
    })
  }

  onResize() {}

  onTouchDown(e) {}

  onTouchMove(e) {}

  onTouchUp(e) {}

  onWheel(e) {}

  onClick() {}

  update() {}

  addEventsListeners() {
    // this.elements.header.addEventListener(
    //   'click',
    //   this.onEnter.bind(this),
    //   {
    //     passive: true,
    //   }
    // )
  }
  removeEventsListeners() {}
  destroy() {
    this.removeEventsListeners()
  }
}
