import Component from '../classes/Component'

export default class AsyncLoad extends Component {
  constructor({ element }) {
    super({ element })

    this.createObserver()
  }
  createObserver() {
    this.observer = new window.IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (this.element.classList.contains('js-lazy')) {
            this.element.src = this.element.getAttribute('data-src')
            // console.log(this.element.src, 'this.element.src')
            this.element.onload = (_) => {
              this.element.classList.remove('js-lazy')
              this.element.classList.add('loaded')
            }
          }
        }
      })
    })

    this.observer.observe(this.element)
  }
}
