import { Plane, Transform } from 'ogl'
import map from 'lodash/map'
import gsap from 'gsap'

import Media from './Media'

export default class {
  constructor({ gl, scene, viewport }) {
    this.gl = gl
    this.scene = scene
    this.viewport = viewport

    this.group = new Transform()
    this.group.setParent(scene)
  }

  createGeometry() {
    this.geometry = new Plane(this.gl)
  }

  createGallery() {}

  /**
   * Animations
   */
  show() {}

  hide() {}

  /**
   * Events
   */
  onResize(e) {}

  onTouchDown({ x, y }) {}
  onTouchMove({ x, y }) {}
  onTouchUp({ x, y }) {}

  onWheel({ pixelX, pixelY }) {}
  /**
   * Loop
   */
  update() {}
  destroy() {
    this.scene.removeChild(this.group)
  }
}
