import { Mesh, Program, Texture } from 'ogl'
import gsap from 'gsap'

import vertex from '../../../../shaders/top-vertex.glsl'
import fragment from '../../../../shaders/top-fragment.glsl'

export default class {
  constructor({ element, geometry, gl, scene, viewport, gallerySizes }) {
    this.element = element
    this.elementImage = element.querySelector('img')
    this.geometry = geometry
    this.gl = gl
    this.scene = scene
    this.viewport = viewport
    this.gallerySizes = gallerySizes

    this.extra = {
      x: 0,
      y: 0,
    }

    this.y = {
      current: 0,
      last: 0,
    }

    this.bounds = null

    this.createTexture()
    this.createProgram()
    this.createMesh()
    this.createBounds({ viewport: this.viewport })

    // this.onResize()
  }

  createTexture() {
    this.image = this.elementImage
    this.texture = window.TEXTURES[this.image.getAttribute('data-src')]

    // ページ遷移時に画像比率更新
    this.image.onload = (_) => {
      this.program.uniforms.uImageSizes.value = [
        this.image.naturalWidth,
        this.image.naturalHeight,
      ]
      // this.texture.image = this.image
    }

    // this.texture = new Texture(this.gl, {
    //   generateMipmaps: false,
    // })

    // image.crossOrigin = 'anonymous'
    // image.src = this.element.getAttribute('data-src')
    // image.onload = (_) => {
    //   this.program.uniforms.uImageSizes.value = [
    //     image.naturalWidth,
    //     image.naturalHeight,
    //   ]
    //   this.texture.image = image
    // }
    // console.log(this.texture, 'Media')
  }

  createProgram() {
    this.program = new Program(this.gl, {
      fragment,
      vertex,
      uniforms: {
        tMap: { value: this.texture },
        uTime: { value: 0 },
        uAlpha: { value: 0 },
        uSpeed: { value: 0 },
        uPlaneSizes: { value: [0, 0] },
        uImageSizes: {
          value: [this.image.naturalWidth, this.image.naturalHeight],
        },
        uViewportSizes: {
          value: [this.viewport.width, this.viewport.height],
        },
        uStrength: { value: 0 },
      },
      transparent: true,
    })

    // console.log(this.program, 'this.program')
  }

  createMesh() {
    this.mesh = new Mesh(this.gl, {
      geometry: this.geometry,
      program: this.program,
    })

    this.mesh.setParent(this.scene)
  }

  createBounds({ viewport }) {
    this.viewport = viewport
    this.bounds = this.element.getBoundingClientRect()

    this.updateScale()
    this.updateX()
    this.updateY()

    this.program.uniforms.uPlaneSizes.value = [
      this.mesh.scale.x,
      this.mesh.scale.y,
    ]

    // this.texture.needsUpdate = true
  }

  /**
   * Animation
   */
  show() {
    gsap.fromTo(
      this.program.uniforms.uAlpha,
      {
        value: 0,
      },
      {
        duration: 0.3,
        value: 1,
      }
    )
  }

  hide() {
    gsap.to(this.program.uniforms.uAlpha, {
      duration: 0.3,
      value: 0,
    })
  }

  /**
   * Events
   */
  onResize(viewport, gallerySizes, scroll) {
    this.extra = {
      x: 0,
      y: 0,
    }

    if (gallerySizes) {
      this.gallerySizes = gallerySizes
    }

    this.createBounds(viewport)

    this.updateY(scroll.current)

    // console.log(this.mesh.scale, 'this.mesh.scale')
  }

  /**
   * Loop
   */
  updateScale() {
    this.mesh.scale.x = this.bounds.width
    this.mesh.scale.y = this.bounds.height
  }

  updateX(x = 0) {
    this.x = this.bounds.left + x

    this.mesh.position.x =
      // this.extraを足して画面外に出た時の処理
      -this.viewport.width / 2 + this.mesh.scale.x / 2 + this.x + this.extra.x
  }

  updateY(y = 0) {
    this.y = this.bounds.top + y

    this.mesh.position.y =
      // this.extraを足して画面外に出た時の処理
      this.viewport.height / 2 - this.mesh.scale.y / 2 - this.y + this.extra.y
  }
  update(scroll, speed) {
    this.updateScale()
    this.updateX(scroll.x)
    // this.updateY(scroll.y)

    // const meshOffsetX = this.mesh.scale.x / 2 + 0.2
    // const viewportOffsetX = this.viewport.width / 2

    // this.isBefore = this.mesh.position.x + meshOffsetX < -viewportOffsetX
    // this.isAfter = this.mesh.position.x - meshOffsetX > viewportOffsetX

    // // elementが画面外に出た場合
    // if (direction === 'left' && this.isBefore) {
    //   this.extra.x += this.gallerySizes.width

    //   this.isBefore = false
    //   this.isAfter = false
    // }

    // if (direction === 'right' && this.isAfter) {
    //   this.extra.x -= this.gallerySizes.width

    //   this.isBefore = false
    //   this.isAfter = false
    // }

    // this.program.uniforms.uStrength.value =
    //   ((scroll.current - scroll.last) / this.viewport.width) * 1

    this.program.uniforms.uSpeed.value = speed
  }
}
