import gsap from 'gsap'

import Animation from '../classes/Animation'

export default class Module extends Animation {
  constructor({ element, elements }) {
    super({
      element,
      elements,
    })
  }

  animateIn() {
    gsap.to(this.element, {
      autoAlpha: 1,
      duration: 0.3,
    })
  }
  animateOut() {
    // gsap.set(this.element, {
    //   autoAlpha: 0,
    // })
  }
}

// let listItems = [...document.querySelectorAll('li')]

// let options = {
//   rootMargin: '-10%',
//   threshold: 0.0,
// }

// let observer = new IntersectionObserver(showItem, options)

// function showItem(entries) {
//   entries.forEach((entry) => {
//     if (entry.isIntersecting) {
//       let letters = [...entry.target.querySelectorAll('span')]
//       letters.forEach((letter, i) => {
//         setTimeout(() => {
//           letter.classList.add('active')
//         }, i * 10)
//       })
//       entry.target.children[0].classList.add('active')
//     }
//   })
//   console.log(entries)
// }

// listItems.forEach((item) => {
//   let newString = ''
//   let itemText = item.children[0].innerText.split('')
//   itemText.map((letter) => {
//     newString +=
//       letter == ' ' ? `<span class="gap"></span` : `<span>${letter}</span>`
//   })
//   console.log(newString)

//   item.innerHTML = newString
//   observer.observe(item)
// })
