import { Plane, Transform, Texture } from 'ogl'
import map from 'lodash/map'

import Gallery from './Gallery'
// import ModalLinks from './Modals'

export default class {
  constructor({ gl, scene, viewport, transition }) {
    this.id = 'top'

    this.gl = gl
    this.scene = scene
    this.viewport = viewport
    this.transition = transition
    this.group = new Transform()

    this.group.setParent(scene)

    // this.onResize({
    //   screen: this.screen,
    //   viewport: this.viewport,
    // })
  }

  // 画像プリロード・テクスチャ設定後に表示
  preloadAssets() {
    this.length = 0
    const images = document.querySelectorAll('img')
    window.ASSETS = []
    images.forEach((image) => {
      const src = image.getAttribute('data-src')
      window.ASSETS.push(src)
    })

    window.ASSETS.forEach((image) => {
      const texture = new Texture(this.gl, {
        generateMipmaps: false,
      })

      const media = new Image()
      media.crossOrigin = 'anonymous'
      media.src = image
      media.onload = (_) => {
        texture.image = media
        this.onAssetLoaded()
      }
      window.TEXTURES[image] = texture
    })
    // console.log(window.TEXTURES, 'window.textures')
  }

  onAssetLoaded() {
    this.length += 1
    if (this.length === window.ASSETS.length) {
      this.onLoaded()
    }
  }

  onLoaded() {
    this.createGeometry()
    this.createGallery()
    // this.createModalLinks()
    // this.createCarousel()
    this.show()
  }

  destroy() {
    this.element.parentNode.removeChild(this.element)
  }

  createGeometry() {
    this.geometry = new Plane(this.gl, {
      widthSegments: 30,
      heightSegments: 30,
    })
  }

  createGallery() {
    this.galleriesElements = document.querySelectorAll('.top__gallery')

    this.galleries = map(this.galleriesElements, (element, index) => {
      return new Gallery({
        element,
        geometry: this.geometry,
        index,
        gl: this.gl,
        scene: this.group,
        viewport: this.viewport,
      })
    })

    // console.log(this.galleries, ' this.galleries')
  }

  // createModalLinks() {
  //   this.modalLinksElements = document.querySelectorAll('.modal__links')

  //   this.modalLinks = map(this.modalLinksElements, (element, index) => {
  //     return new ModalLinks({
  //       element,
  //       geometry: this.geometry,
  //       index,
  //       gl: this.gl,
  //       scene: this.group,
  //       viewport: this.viewport,
  //       transition: this.transition,
  //     })
  //   })

  //   // console.log(this.modalLinks, ' this.modalLinks')
  // }

  /**
   * Animations
   */
  show() {
    map(this.galleries, (gallery) => gallery.show())
  }

  hide() {
    map(this.galleries, (gallery) => gallery.hide())
  }

  /**
   * Events
   */
  onResize(e) {
    // console.log(e, 'e')
    map(this.galleries, (gallery) => gallery.onResize(e))
  }

  onTouchDown(e) {
    map(this.galleries, (gallery) => gallery.onTouchDown(e))
  }
  onTouchMove(e) {
    map(this.galleries, (gallery) => gallery.onTouchMove(e))
  }
  onTouchUp(e) {
    map(this.galleries, (gallery) => gallery.onTouchUp(e))
  }

  onWheel({ pixelX, pixelY }) {
    map(this.galleries, (gallery) => gallery.onWheel(pixelX, pixelY))
  }

  /**
   * Loop
   */
  update(scroll) {
    map(this.galleries, (gallery) => gallery.update(scroll))
  }
  destroy() {
    this.scene.removeChild(this.group)
  }
}
