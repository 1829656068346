import gsap from 'gsap'

import Page from '../../classes/Page'
import Carousel from '../../components/Carousel'
import VideoPlayer from '../../components/VideoPlayer'

import SectionScroll from '../../components/SectionScroll'
import GhostCursor from '../../components/Cursor'

export default class Top extends Page {
  constructor() {
    super({
      id: 'top',

      element: '.top',
      elements: {
        wrapper: '.top__wrapper',
        navigation: document.querySelector('.navigation'),
        media: '.top__gallery__media',
      },
    })

    this.y = {
      start: 0,
      distance: 0,
      end: 0,
      current: 0,
      last: 0,
      target: 0,
    }

    this.isDown = false
  }

  create() {
    super.create()

    // this.elements.media[0].addEventListener('click', (_) => {
    //   console.log('clicked')
    // })

    this.carousel = new Carousel()

    this.videoPlayer = new VideoPlayer()

    this.createSectionScroll()
    this.createCursor()
  }

  createSectionScroll() {
    // console.log('created inertia')
    this.sectionScroll = new SectionScroll(this.elements.wrapper)
  }

  createCursor() {
    this.cursor = new GhostCursor({
      gsapParams: {
        duration: 0.3,
        ease: 'Power4.easeInOut',
      },
    })
  }

  show() {
    super.show()

    if (this.sectionScroll && this.sectionScroll.show) {
      this.sectionScroll.show()
    }
  }

  hide() {
    super.hide()

    if (this.sectionScroll && this.sectionScroll.hide) {
      this.sectionScroll.hide()
    }
  }

  /**
   * Events
   */

  onResize() {
    super.onResize()

    // if (this.sectionScroll) {
    //   this.sectionScroll.onResize()
    // }

    if (this.carousel) this.carousel.onResize()
  }

  onTouchDown(e) {
    super.onTouchDown()

    if (this.carousel && this.carousel.isDragging) return

    if ('ontouchstart' in document) {
      this.isDown = true
      this.y.start = e.touches ? e.touches[0].clientY : e.clientY
    }

    if (this.sectionScroll) {
      this.sectionScroll.onTouchDown(this.y.start)
    }

    if (this.cursor) {
      this.cursor.onTouchDown(e)
    }
  }

  onTouchMove(e) {
    super.onTouchMove()

    if ('ontouchstart' in document) {
      if (!this.isDown) return
      this.y.end = e.touches ? e.touches[0].clientY : e.clientY

      // this.scroll.target += (this.y.start - this.y.end) * 1.4
      // this.y.start = this.y.end

      // this.scroll.target = gsap.utils.clamp(
      //   0,
      //   this.scroll.limit,
      //   this.scroll.target
      // )
    }

    if (this.sectionScroll) {
      this.sectionScroll.onTouchMove(this.y.end)
    }

    if (this.cursor) {
      this.cursor.onTouchMove(e)
    }
  }

  onTouchUp(e) {
    super.onTouchUp()

    if ('ontouchstart' in document) {
      this.isDown = false
    }

    if (this.cursor) {
      this.cursor.onTouchUp(e)
    }
  }

  onClick(e) {}

  onWheel(e) {
    super.onWheel(e)

    if (this.sectionScroll) {
      this.sectionScroll.onWheel(e)
    }
  }

  update() {
    super.update()

    if (this.carousel) this.carousel.update()

    if (this.cursor) {
      this.cursor.update()
    }
  }

  addEventsListeners() {
    super.addEventsListeners()

    if (this.sectionScroll) {
      this.sectionScroll.addEventsListeners()
    }

    if (this.carousel) this.carousel.addEventsListeners()

    if (this.cursor) {
      this.cursor.addEventsListeners()
    }
  }

  removeEventsListeners() {
    if (this.sectionScroll) {
      this.sectionScroll.removeEventsListeners()
    }

    if (this.carousel) this.carousel.removeEventsListeners()

    if (this.cursor) {
      this.cursor.removeEventsListeners()
    }
  }
  destroy() {
    super.destroy()
    if (this.sectionScroll) {
      this.sectionScroll.destroy()
    }
  }
}
