import NormalizeWheel from 'normalize-wheel'
import debounce from './utils/debounce'

import userAgent from './classes/userAgent.js'

import each from 'lodash/each'
import Stats from 'stats.js'

import Canvas from './components/Canvas'

import Preloader from './components/Preloader'
import Navigation from './components/Navigation'
import Footer from './components/Footer'

import Top from './pages/Top'
import Sub from './pages/Sub'
// import Info from './pages/Info'

function calcVw(px, width) {
  const value = (100 / width) * px
  console.log(value, 'vw')
}

calcVw(334, 750)

class App {
  constructor() {
    userAgent.check()

    this.createContent()
    // this.createCanvas()

    this.createNavigation()
    this.createPages()
    this.createPreloader()
    this.createFooter()

    this.addEventsListeners()
    this.addLinkListeners()

    this.onResize()

    this.update()

    // Stats(開発時のみ)
    this.createStats()
  }

  /**
   * Stats(開発時のみ)
   */
  createStats() {
    this.stats = new Stats()
    this.stats.showPanel(0)
    Object.assign(this.stats.dom.style, {
      position: 'fixed',
      height: 'max-content',
      left: '0',
      right: 'auto',
      top: 'auto',
      bottom: '0',
    })
    document.body.appendChild(this.stats.dom)
  }

  createNavigation() {
    this.navigation = new Navigation({
      template: this.template,
    })
  }

  createPreloader() {
    this.preloader = new Preloader({
      canvas: this.canvas,
    })
    this.preloader.show()

    this.preloader.once('completed', this.onPreloaded.bind(this))
  }

  createCanvas() {
    this.canvas = new Canvas({ template: this.template })
  }

  createContent() {
    this.content = document.querySelector('.content')
    this.template = this.content.getAttribute('data-template')
  }

  createPages() {
    this.pages = {
      top: new Top(),
      sub: new Sub(),
      // info: new Info(),
    }
    this.page = this.pages[this.template]
    this.page.create()
    // console.log(this.page, 'page')
  }

  createFooter() {
    this.footer = new Footer()
  }

  reloadScripts() {
    let scripts = []
    const oldScripts = [...document.querySelectorAll('.js-scripts')]

    for (let i = 0; i < oldScripts.length; i++) {
      const newScript = document.createElement('script')
      const attrs = oldScripts[i].attributes
      // console.log(attrs)

      for (let i = 0, len = attrs.length; i < len; i++) {
        const attr = attrs[i]
        newScript.setAttribute(attr.name, attr.value)
      }
      newScript.innerHTML = oldScripts[i].innerHTML
      scripts.push(newScript)
      oldScripts[i].parentNode.removeChild(oldScripts[i])
    }

    for (let i = 0; i < scripts.length; i++) {
      document.head.appendChild(scripts[i])
    }
  }

  /**
   * Events
   */

  onPopState() {
    this.onChange({
      url: window.location.pathname,
      push: false,
    })
  }

  onPreloaded() {
    // this.onResize()
    if (this.canvas) {
      this.canvas.onPreloaded()
    }

    // プリロード後に表示
    this.page.show()
  }

  async onChange({ url, push = true }) {
    if (this.canvas) {
      this.canvas.onChangeStart(this.template, url)
    }
    this.footer.onChangeStart()

    await this.page.hide()
    // console.log(url, 'url')

    const request = await window.fetch(url)

    if (request.status === 200) {
      const html = await request.text()
      const div = document.createElement('div')

      div.innerHTML = html
      const divContent = div.querySelector('#pjax-content')

      const title = div.querySelector('title')
      document.title = title.innerText

      if (push) {
        window.history.pushState({}, '', url)
      }

      this.template = divContent.getAttribute('data-template')

      this.navigation.onChange(this.template)

      this.content.setAttribute('data-template', this.template)
      this.content.innerHTML = divContent.innerHTML

      if (this.canvas) {
        this.canvas.onChangeEnd(this.template)
      }

      this.page = this.pages[this.template]
      this.page.create()

      this.onResize()
      this.page.show()
      // console.log(this.page.isLoaded, 'loaded Index')

      this.reloadScripts()

      this.addLinkListeners()
    } else {
      console.log('Error')
    }
  }

  onResize() {
    console.log(
      '%cResized',
      'background-color:yellow; color: black; padding: 1px 4px;'
    )

    if (this.navigation && this.navigation.onResize) {
      this.navigation.onResize()
    }

    if (this.page && this.page.onResize) {
      this.page.onResize()
    }

    // if (this.footer && this.footer.onResize) {
    //   this.footer.onResize()
    // }

    window.requestAnimationFrame((_) => {
      if (this.canvas && this.canvas.onResize) {
        this.canvas.onResize()
      }
    })
  }

  onTouchDown(e) {
    if (this.canvas && this.canvas.onTouchDown) {
      this.canvas.onTouchDown(e)
    }

    if (this.navigation && this.navigation.onTouchDown) {
      this.navigation.onTouchDown(e)
    }

    if (this.page && this.page.onTouchDown) {
      this.page.onTouchDown(e)
    }
  }
  onTouchMove(e) {
    if (this.canvas && this.canvas.onTouchMove) {
      this.canvas.onTouchMove(e)
    }

    if (this.navigation && this.navigation.onTouchMove) {
      this.navigation.onTouchMove(e)
    }

    if (this.page && this.page.onTouchMove) {
      this.page.onTouchMove(e)
    }
  }
  onTouchUp(e) {
    if (this.canvas && this.canvas.onTouchUp) {
      this.canvas.onTouchUp(e)
    }

    if (this.navigation && this.navigation.onTouchUp) {
      this.navigation.onTouchUp(e)
    }

    if (this.page && this.page.onTouchUp) {
      this.page.onTouchUp(e)
    }
  }

  onWheel(e) {
    const normalizedWheel = NormalizeWheel(e)

    if (this.canvas && this.canvas.onWheel) {
      this.canvas.onWheel(normalizedWheel)
    }

    if (this.navigation && this.navigation.onWheel) {
      this.navigation.onWheel(normalizedWheel)
    }

    if (this.page && this.page.onWheel) {
      if (this.navigation && this.navigation.isOpen) return
      this.page.onWheel(normalizedWheel)
    }

    if (this.footer && this.footer.onWheel) {
      this.footer.onWheel(normalizedWheel)
    }
  }

  onClick(e) {
    // e.preventDefault()
    if (this.page && this.page.onClick) {
      this.page.onClick(e)
    }
  }

  toPageTop() {
    if (this.page.id === 'top' && this.page.sectionScroll) {
      this.page.sectionScroll.move(0)
    } else {
      if (this.page && this.page.scroll) {
        this.page.scroll.target = 0
        this.footer.onChangeStart()
      }
    }
  }

  toPrevPage() {
    history.back()
  }

  onPjax(link) {
    const { href } = link

    if (href == location.href || href.replace(/index\..+/, '') == location.href)
      return

    console.log(`from ${window.location.pathname} to ${href}`)
    this.onChange({ url: href })
  }

  onSmoothScroll(e) {
    const href = e.target.hash
    const deCode = decodeURI(href)
    const target = document.querySelector(deCode)
    const boundsTop = target.getBoundingClientRect().top
    const scrollY = this.page.scroll.target + boundsTop

    if (this.page && this.page.scroll) {
      this.page.scroll.target = scrollY
    }
  }

  /**
   * Loop
   */
  update() {
    window.requestAnimationFrame(this.update.bind(this))
    if (this.stats) {
      this.stats.begin()
    }

    if (this.navigation && this.navigation.update) {
      this.navigation.update()
    }

    if (this.page && this.page.update) {
      this.page.update()
    }

    if (this.canvas && this.canvas.update) {
      // Page.jsからscrollを渡す
      this.canvas.update(this.page.scroll)
    }

    if (this.footer && this.footer.update) {
      this.footer.update()
    }

    if (this.stats) {
      this.stats.end()
    }
  }

  /**
   * Listeners
   */
  addEventsListeners() {
    window.addEventListener('mousewheel', this.onWheel.bind(this), {
      passive: true,
    })
    window.addEventListener('click', this.onClick.bind(this), {
      passive: false,
    })

    if ('ontouchstart' in document) {
      window.addEventListener('touchstart', this.onTouchDown.bind(this), {
        passive: false,
      })
      window.addEventListener('touchmove', this.onTouchMove.bind(this), {
        passive: false,
      })
      window.addEventListener('touchend', this.onTouchUp.bind(this), {
        passive: true,
      })
    } else {
      window.addEventListener('mousedown', this.onTouchDown.bind(this), {
        passive: false,
      })
      window.addEventListener('mousemove', this.onTouchMove.bind(this), {
        passive: false,
      })
      window.addEventListener('mouseup', this.onTouchUp.bind(this), {
        passive: true,
      })
    }

    window.addEventListener('resize', debounce(this.onResize.bind(this), 500), {
      passive: true,
    })

    // window.addEventListener('resize', this.onResize.bind(this))

    // 履歴追加（戻る／進む）
    window.addEventListener('popstate', this.onPopState.bind(this), {
      passive: true,
    })

    // Page Top Button
    if (this.footer && this.footer.footerBtn) {
      this.footer.footerBtn.elements.pageTopBtn.addEventListener(
        'click',
        this.toPageTop.bind(this),
        { passive: true }
      )
    }

    // Prev Page Button
    if (this.footer && this.footer.footerBtn) {
      this.footer.footerBtn.elements.PrevPageBtn.addEventListener(
        'click',
        this.toPrevPage.bind(this),
        { passive: true }
      )
    }
  }

  addLinkListeners() {
    // Pjax Links
    const links = document.querySelectorAll('.js-async')

    each(links, (link) => {
      link.onclick = (e) => {
        e.preventDefault()
        this.onPjax(link)
      }
    })

    // Hash Links
    const HashLinks = document.querySelectorAll("a[href^='#']")

    each(HashLinks, (link) => {
      link.onclick = (e) => {
        e.preventDefault()
        this.onSmoothScroll(e)
      }
    })
  }
}

new App()
